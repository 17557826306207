var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$root.$data.portalInfo.useBalanceEntries &&
    _vm.$root.$data.me.access.ST1
    ? _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [_c("page-snippet", { attrs: { name: "royalties" } })],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "no-body": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("Filters / Options")) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2246849746
                      )
                    },
                    [
                      _c(
                        "b-card-body",
                        { style: { minHeight: _vm.beHeight + "px" } },
                        [
                          _c(
                            "b-container",
                            { staticClass: "p-0", attrs: { fluid: "" } },
                            [
                              false
                                ? _c(
                                    "b-row",
                                    { staticClass: "mb-3" },
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("Beneficiaries")) +
                                              " "
                                          ),
                                          _c("ApiLookup", {
                                            attrs: {
                                              source:
                                                "accounting/find-statement-beneficiaries",
                                              filter: _vm.filter
                                            },
                                            model: {
                                              value: _vm.filter.selectedBfs,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filter,
                                                  "selectedBfs",
                                                  $$v
                                                )
                                              },
                                              expression: "filter.selectedBfs"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-3",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("div", { staticClass: "mr-2" }, [
                                        _vm._v(_vm._s(_vm.$t("Period")) + ":")
                                      ]),
                                      _c(
                                        "span",
                                        { staticClass: "mt-2 text-nowrap" },
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              options: _vm.apOptions,
                                              size: "sm"
                                            },
                                            model: {
                                              value: _vm.filter.apFrom,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filter,
                                                  "apFrom",
                                                  $$v
                                                )
                                              },
                                              expression: "filter.apFrom"
                                            }
                                          }),
                                          _vm._v(" - "),
                                          _c("b-form-select", {
                                            attrs: {
                                              options: _vm.apOptions,
                                              size: "sm"
                                            },
                                            model: {
                                              value: _vm.filter.apTo,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filter,
                                                  "apTo",
                                                  $$v
                                                )
                                              },
                                              expression: "filter.apTo"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  false
                                    ? _c(
                                        "b-col",
                                        {
                                          staticClass: "mb-3",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("div", { staticClass: "mr-2" }, [
                                            _vm._v("Export type:")
                                          ]),
                                          _c(
                                            "b-button-group",
                                            { staticClass: "mr-4" },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  class: {
                                                    active:
                                                      _vm.exportType == "xlsx"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.exportType = "xlsx"
                                                    }
                                                  }
                                                },
                                                [_vm._v(" Excel ")]
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  class: {
                                                    active:
                                                      _vm.exportType == "csv"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.exportType = "csv"
                                                    }
                                                  }
                                                },
                                                [_vm._v(" CSV ")]
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  class: {
                                                    active:
                                                      _vm.exportType == "tab"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.exportType = "tab"
                                                    }
                                                  }
                                                },
                                                [_vm._v(" TAB ")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-3",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("div", { staticClass: "mr-2" }, [
                                        _vm._v(_vm._s(_vm.$t("Results")) + ":")
                                      ]),
                                      _c(
                                        "b-button-group",
                                        { staticClass: "mr-2" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              class: {
                                                active: _vm.perPage == 5
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.perPage = 5
                                                }
                                              }
                                            },
                                            [_vm._v(" 5 ")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              class: {
                                                active: _vm.perPage == 10
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.perPage = 10
                                                }
                                              }
                                            },
                                            [_vm._v(" 10 ")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              class: {
                                                active: _vm.perPage == 25
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.perPage = 25
                                                }
                                              }
                                            },
                                            [_vm._v(" 25 ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-3",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(_vm.$t("Number format")) + ":"
                                        )
                                      ]),
                                      _c(
                                        "b-button-group",
                                        { staticClass: "mr-2" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              class: {
                                                active:
                                                  _vm.numberLocale == "intl"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.numberLocale = "intl"
                                                }
                                              }
                                            },
                                            [_vm._v(" 1 000.00 ")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              class: {
                                                active: _vm.numberLocale == "en"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.numberLocale = "en"
                                                }
                                              }
                                            },
                                            [_vm._v(" 1,000.00 ")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              class: {
                                                active: _vm.numberLocale == "nl"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.numberLocale = "nl"
                                                }
                                              }
                                            },
                                            [_vm._v(" 1.000,00 ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "no-body": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _vm._v(" " + _vm._s(_vm.$t("Statements")) + " ")
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3248356008
                      )
                    },
                    [
                      _c(
                        "b-card-body",
                        { style: { height: _vm.beHeight + "px" } },
                        [
                          _vm.bfs.selected == null
                            ? _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "Click on a beneficiary in the table below to view the entries"
                                      )
                                    ) +
                                    ". "
                                )
                              ])
                            : _vm._e(),
                          _vm.bfs.selected != null
                            ? _c(
                                "div",
                                { staticClass: "mb-1" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { size: "sm", variant: "primary" },
                                      on: { click: _vm.entriesStateAll }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("Select all")) + " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { size: "sm", variant: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.entriesStateAll(false)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("Unselect all")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { id: "exportWrapper" }
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            size: "sm",
                                            variant: "primary",
                                            disabled:
                                              _vm.entriesSelected.length == 0 ||
                                              _vm.entries.exportBusy
                                          },
                                          on: {
                                            click: _vm.entriesExportSelected
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("Export selected")
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.entriesSelected.length >
                                                    0,
                                                  expression:
                                                    "entriesSelected.length > 0"
                                                }
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "(" +
                                                  _vm._s(
                                                    _vm.entriesSelected.length
                                                  ) +
                                                  ")"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("b-popover", {
                                    attrs: {
                                      disabled:
                                        true ||
                                        _vm.$root.$data.me
                                          .isImpersonatingOrSuperuser,
                                      target: "exportWrapper",
                                      triggers: "hover",
                                      content:
                                        "This option will be available soon"
                                    }
                                  }),
                                  _c("b-spinner", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.entries.exportBusy,
                                        expression: "entries.exportBusy"
                                      }
                                    ],
                                    staticClass: "mr-2",
                                    attrs: { small: "", label: "Spinning" }
                                  }),
                                  _vm.$root.$data.me.access.ROY
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            size: "sm",
                                            variant: "primary",
                                            disabled:
                                              _vm.entriesSelected.length == 0
                                          },
                                          on: { click: _vm.entriesViewSelected }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("View selected")) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.entriesSelected.length >
                                                    0,
                                                  expression:
                                                    "entriesSelected.length > 0"
                                                }
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "(" +
                                                  _vm._s(
                                                    _vm.entriesSelected.length
                                                  ) +
                                                  ")"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.bfs.selected != null
                            ? _c("b-table", {
                                staticClass: "smaller-table",
                                staticStyle: { "overflow-y": "auto" },
                                style: { height: _vm.beHeight - 75 + "px" },
                                attrs: {
                                  "sticky-header": "",
                                  small: "",
                                  striped: "",
                                  hover: "",
                                  items: _vm.bfs.selected.balanceEntries,
                                  fields: _vm.entries.fields
                                },
                                on: { "row-clicked": _vm.onEntriesRowClicked },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "cell(selected)",
                                      fn: function(data) {
                                        return [
                                          data.item.type == 2 &&
                                          data.item.statementReference != null
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold text-primary"
                                                },
                                                [
                                                  data.item.selected
                                                    ? [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              "aria-hidden":
                                                                "true"
                                                            }
                                                          },
                                                          [_vm._v("✓")]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "sr-only"
                                                          },
                                                          [_vm._v("Selected")]
                                                        )
                                                      ]
                                                    : [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              "aria-hidden":
                                                                "true"
                                                            }
                                                          },
                                                          [_vm._v("-")]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "sr-only"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Not selected"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    },
                                    {
                                      key: "head(amount)",
                                      fn: function(data) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-nowrap text-right"
                                            },
                                            [_vm._v(_vm._s(_vm.$t(data.label)))]
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "head(dateTime)",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "text-nowrap" },
                                            [_vm._v(_vm._s(_vm.$t("Date")))]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "cell(accountingPeriod)",
                                      fn: function(data) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-nowrap text-right"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f(
                                                    "formatAccountingPeriod"
                                                  )(data.item.accountingPeriod)
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "cell(amount)",
                                      fn: function(data) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-nowrap text-right",
                                              staticStyle: {
                                                "font-family":
                                                  "'Roboto Mono', monospace"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("numberFormat")(
                                                    data.item.amount,
                                                    _vm.numberDecimals,
                                                    _vm.numberLocale
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "cell(dateTime)",
                                      fn: function(data) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "text-nowrap" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("dateFormat")(
                                                    data.item.dateTime
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2778984771
                                )
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "no-body": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _vm._v(" " + _vm._s(_vm.$t("Summary")) + " ")
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2394140658
                      )
                    },
                    [
                      _c(
                        "b-card-body",
                        { style: { height: _vm.beHeight + "px" } },
                        [
                          _vm.summary.length == 0
                            ? _c("div", [_vm._v("No data available")])
                            : _vm._e(),
                          _c("b-table", {
                            staticClass: "smaller-table text-nowrap",
                            attrs: {
                              "thead-class": "d-none",
                              small: "",
                              striped: "",
                              hover: "",
                              responsive: "",
                              items: _vm.summary,
                              fields: _vm.summaryFields
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(value)",
                                  fn: function(data) {
                                    return [
                                      data.item.value != null
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-nowrap text-right",
                                              staticStyle: {
                                                "font-family":
                                                  "'Roboto Mono', monospace"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("numberFormat")(
                                                      data.item.value,
                                                      0,
                                                      _vm.numberLocale
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                },
                                {
                                  key: "cell(percentage)",
                                  fn: function(data) {
                                    return [
                                      data.item.percentage != null
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-nowrap text-right",
                                              class: {
                                                "font-weight-bold": true,
                                                "text-success":
                                                  data.item.percentage > 0,
                                                "text-danger":
                                                  data.item.percentage < 0
                                              },
                                              staticStyle: {
                                                "font-family":
                                                  "'Roboto Mono', monospace"
                                              }
                                            },
                                            [
                                              data.item.percentage > 0
                                                ? _c("span", [_vm._v("+")])
                                                : _vm._e(),
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("numberFormat")(
                                                    data.item.percentage,
                                                    0,
                                                    _vm.numberLocale
                                                  )
                                                ) + " % "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              4036969490
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-card",
                    [
                      _c(
                        "div",
                        { attrs: { slot: "header" }, slot: "header" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("Beneficiaries")) +
                              " - " +
                              _vm._s(
                                (_vm.bfs.currentPage - 1) * _vm.perPage + 1
                              ) +
                              " - " +
                              _vm._s(_vm.bfs.currentPage * _vm.perPage) +
                              " of " +
                              _vm._s(_vm.bfs.totalRows) +
                              " "
                          ),
                          _c("b-spinner", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.bfs.loading,
                                expression: "bfs.loading"
                              }
                            ],
                            attrs: { small: "", label: "Spinning" }
                          })
                        ],
                        1
                      ),
                      _c("b-pagination", {
                        attrs: {
                          "total-rows": _vm.bfs.totalRows,
                          "per-page": _vm.perPage,
                          align: "fill",
                          size: "sm"
                        },
                        model: {
                          value: _vm.bfs.currentPage,
                          callback: function($$v) {
                            _vm.$set(_vm.bfs, "currentPage", $$v)
                          },
                          expression: "bfs.currentPage"
                        }
                      }),
                      _c("b-table", {
                        staticClass: "smaller-table",
                        attrs: {
                          small: "",
                          striped: "",
                          id: "bfs-table",
                          responsive: "",
                          selectable: "",
                          "select-mode": "single",
                          items: _vm.bfProvider,
                          fields: _vm.bfs.fields,
                          "current-page": _vm.bfs.currentPage,
                          "per-page": _vm.perPage,
                          filter: _vm.filter
                        },
                        on: { "row-selected": _vm.onBfSelected },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "head()",
                              fn: function(scope) {
                                return [
                                  _c("div", { staticClass: "text-nowrap" }, [
                                    _vm._v(" " + _vm._s(scope.label) + " ")
                                  ])
                                ]
                              }
                            },
                            {
                              key: "cell()",
                              fn: function(scope) {
                                return [
                                  _c("div", { staticClass: "text-nowrap" }, [
                                    _vm._v(" " + _vm._s(scope.value) + " ")
                                  ])
                                ]
                              }
                            },
                            {
                              key: "head(statementReference)",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "text-nowrap" }, [
                                    _vm._v(_vm._s(_vm.$t("Reference")))
                                  ])
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "head(beneficiaryCode)",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "text-nowrap" }, [
                                    _vm._v(_vm._s(_vm.$t("Beneficiary Code")))
                                  ])
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "head(accountingPeriod)",
                              fn: function(data) {
                                return [
                                  _c("div", { staticClass: "text-nowrap" }, [
                                    _vm._v(_vm._s(_vm.$t(data.label)))
                                  ])
                                ]
                              }
                            },
                            {
                              key: "head(currency)",
                              fn: function(data) {
                                return [
                                  _c("div", { staticClass: "text-nowrap" }, [
                                    _vm._v(_vm._s(_vm.$t(data.label)))
                                  ])
                                ]
                              }
                            },
                            {
                              key: "head(openingBalance)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "text-nowrap text-right" },
                                    [_vm._v(_vm._s(_vm.$t(data.label)))]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(accountingPeriod)",
                              fn: function(data) {
                                return [
                                  _c("div", { staticClass: "text-nowrap" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatAccountingPeriod")(
                                          data.item.accountingPeriod
                                        )
                                      )
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "cell(openingBalance)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-nowrap text-right",
                                      staticStyle: {
                                        "font-family":
                                          "'Roboto Mono', monospace"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numberFormat")(
                                            data.item.openingBalance,
                                            _vm.numberDecimals,
                                            _vm.numberLocale
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "head(paymentsAmount)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "text-nowrap text-right" },
                                    [_vm._v(_vm._s(_vm.$t(data.label)))]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(paymentsAmount)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-nowrap text-right",
                                      staticStyle: {
                                        "font-family":
                                          "'Roboto Mono', monospace"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numberFormat")(
                                            data.item.paymentsAmount,
                                            _vm.numberDecimals,
                                            _vm.numberLocale
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "head(advancesAmount)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "text-nowrap text-right" },
                                    [_vm._v(_vm._s(_vm.$t(data.label)))]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(advancesAmount)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-nowrap text-right",
                                      staticStyle: {
                                        "font-family":
                                          "'Roboto Mono', monospace"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numberFormat")(
                                            data.item.advancesAmount,
                                            _vm.numberDecimals,
                                            _vm.numberLocale
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "head(royaltyAmount)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "text-nowrap text-right" },
                                    [_vm._v(_vm._s(_vm.$t(data.label)))]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(royaltyAmount)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-nowrap text-right",
                                      staticStyle: {
                                        "font-family":
                                          "'Roboto Mono', monospace"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numberFormat")(
                                            data.item.royaltyAmount,
                                            _vm.numberDecimals,
                                            _vm.numberLocale
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "head(adjustmentsAmount)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "text-nowrap text-right" },
                                    [_vm._v(_vm._s(_vm.$t(data.label)))]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(adjustmentsAmount)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-nowrap text-right",
                                      staticStyle: {
                                        "font-family":
                                          "'Roboto Mono', monospace"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numberFormat")(
                                            data.item.adjustmentsAmount,
                                            _vm.numberDecimals,
                                            _vm.numberLocale
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "head(closingBalance)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "text-nowrap text-right" },
                                    [_vm._v(_vm._s(_vm.$t(data.label)))]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(closingBalance)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-nowrap text-right",
                                      staticStyle: {
                                        "font-family":
                                          "'Roboto Mono', monospace"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numberFormat")(
                                            data.item.closingBalance,
                                            _vm.numberDecimals,
                                            _vm.numberLocale
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1038274917
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }